import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import * as Sentry from '@sentry/react';

import App from './App';
import {
    SplashScreen,
    SplashScreenProvider,
} from './components/ui/SplashScreen';
import reportWebVitals from './reportWebVitals';

Sentry.init({
    enabled: !!process.env.REACT_APP_SENTRY_DSN?.length,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
});

ReactDOM.render(
    <React.StrictMode>
        <SplashScreenProvider>
            <IntlProvider locale="nl">
                <Suspense fallback={<SplashScreen />}>
                    <App />
                </Suspense>
            </IntlProvider>
        </SplashScreenProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
