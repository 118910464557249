import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Page } from '../components/ui';
import { SubHeader } from '../components/ui/SubHeader';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export const TestPage = () => {
    const { t } = useTranslation('profile');

    return (
        <Page
            subHeader={
                <SubHeader>
                    <SubHeader.Title title="Testing UI elements" />
                </SubHeader>
            }
        >
            <Row>
                <Col>
                    <div className="card card-custom gutter-b">
                        <div className="card-header">
                            <div className="card-title">
                                <h3 className="card-label">Toasts</h3>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="example">
                                <div className="example-preview">
                                    <p>
                                        <button
                                            type="button"
                                            className="btn btn-success mr-2"
                                            onClick={() =>
                                                toast.success(
                                                    'Successfully done something!'
                                                )
                                            }
                                        >
                                            Success
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger mr-2"
                                            onClick={() =>
                                                toast.error(
                                                    'An error has occured!'
                                                )
                                            }
                                        >
                                            Danger
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary mr-2"
                                            onClick={() =>
                                                toast.loading(
                                                    'Loading something for you...'
                                                )
                                            }
                                        >
                                            Loading
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Page>
    );
};
