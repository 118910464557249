import React, { FC, PropsWithChildren } from 'react';
import cn from 'classnames';
import H, { createLocation } from 'history';
import { Link, LinkProps, match } from 'react-router-dom';
import { __RouterContext as RouterContext, matchPath } from 'react-router';

import { Svg } from './Svg';

const resolveToLocation = (to: any, currentLocation: any) =>
    typeof to === 'function' ? to(currentLocation) : to;

const normalizeToLocation = (to: any, currentLocation: any) => {
    return typeof to === 'string'
        ? createLocation(to, null, undefined, currentLocation)
        : to;
};

interface SideMenuItemProps<S = H.LocationState> extends LinkProps<S> {
    exact?: boolean;
    strict?: boolean;
    isActive?<Params extends { [K in keyof Params]?: string }>(
        match: match<Params> | null,
        location: H.Location<S>
    ): boolean;
    location?: H.Location<S>;
    sensitive?: boolean;
    icon: string;
    label: string;
    [key: string]: any;
}

export const SideMenuItem: FC<SideMenuItemProps> = ({
    className: classNameProp,
    isActive: isActiveProp,
    location: locationProp,
    sensitive,
    strict,
    exact,
    to,
    icon,
    label,
    ...rest
}) => {
    return (
        <RouterContext.Consumer>
            {context => {
                const currentLocation = locationProp || context.location;
                const toLocation = normalizeToLocation(
                    resolveToLocation(to, currentLocation),
                    currentLocation
                );
                const { pathname: path } = toLocation;

                // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
                const escapedPath =
                    path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

                const match = escapedPath
                    ? matchPath(currentLocation.pathname, {
                          path: escapedPath,
                          exact,
                          sensitive,
                          strict,
                      })
                    : null;
                const isActive = !!(isActiveProp
                    ? isActiveProp(match, currentLocation)
                    : match);

                const className = cn(classNameProp, 'menu-item', {
                    'menu-item-active': isActive,
                });

                const props = {
                    to: toLocation,
                    ...rest,
                };

                return (
                    <li className={className}>
                        <Link className="menu-link" {...props}>
                            <Svg
                                className="menu-icon"
                                src={icon}
                                width="24px"
                                height="auto"
                            />
                            <span className="menu-text">{label}</span>
                        </Link>
                    </li>
                );
            }}
        </RouterContext.Consumer>
    );
};

export const SideMenuItemSection = ({ label }: { label: string }) => {
    return (
        <li className="menu-section">
            <h4 className="menu-text">{label}</h4>
            <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>
    );
};

export const SideMenu: FC<PropsWithChildren<{}>> = ({ children }) => {
    return (
        <div className="aside-menu-wrapper flex-column-fluid">
            <div className="aside-menu my-4">
                <ul className="menu-nav">{children}</ul>
            </div>
        </div>
    );
};
