import React, { FC, PropsWithChildren } from 'react';
import { Container } from 'react-bootstrap';
import { useQueryErrorResetBoundary } from 'react-query';
import { ErrorBoundary } from '@sentry/react';

import { Brand } from 'components/ui/Brand';
import { Header } from 'components/ui/Header';
import { Menu } from 'components/Routes';
import { Error } from 'components/Error';
import { Toasts } from '.';

interface LayoutProps {}

export const Layout: FC<PropsWithChildren<LayoutProps>> = ({ children }) => {
    const { reset } = useQueryErrorResetBoundary();

    return (
        <div className="d-flex flex-column flex-root flex-grow-1 h-100">
            <Toasts />
            <div className="d-flex flex-row flex-column-fluid page flex-grow-1">
                <div className="aside aside-left aside-fixed d-flex flex-column flex-row-auto">
                    <Brand />
                    <Menu />
                </div>
                <div className="d-flex flex-column flex-row-fluid wrapper flex-grow-1">
                    <Header />
                    <div className="content d-flex flex-column flex-column-fluid">
                        <ErrorBoundary
                            onReset={reset}
                            fallback={({ error, eventId, resetError }) => {
                                return (
                                    <Error
                                        error={error}
                                        identifier={eventId}
                                        onFixClick={() => resetError()}
                                    />
                                );
                            }}
                        >
                            <Container className="h-100">{children}</Container>
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
        </div>
    );
};
