import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import cn from 'classnames';
import { FormField, Message } from '@ory/kratos-client';

import { initiateFlowRedirect } from 'lib/auth/helpers/sdk';
import { getLocalizedKratosMessage } from '../components/errors';

export const useKratosFlow = <TFlow,>(
    redirectPath: string,
    flowHandler: (flowId: string) => Promise<TFlow>
) => {
    const location = useLocation();

    const query = qs.parse(location.search.replace('?', ''));
    const flowId = query.flow as string | null;

    const [config, setConfig] = useState<TFlow | undefined>(undefined);

    const initializeFlow = useCallback(async () => {
        try {
            if (flowId) {
                const flow = await flowHandler(flowId);
                setConfig(flow);
            } else {
                initiateFlowRedirect(redirectPath);
            }
        } catch (err) {
            console.error(err);

            initiateFlowRedirect(redirectPath);
        }
    }, [flowId, redirectPath, flowHandler]);

    useEffect(() => {
        initializeFlow();
    }, [initializeFlow]);

    return config;
};

export const KratosMessages = ({ messages }: { messages?: Message[] }) => {
    const { t } = useTranslation('account');

    if (!messages || messages.length === 0) {
        return null;
    }

    return (
        <div className="text-center pb-5">
            {messages.map(m => {
                return (
                    <p
                        key={m.id}
                        className={cn({
                            'text-danger': m.type === 'error',
                            'text-primary': m.type === 'info',
                        })}
                    >
                        {getLocalizedKratosMessage(t, m)}
                    </p>
                );
            })}
        </div>
    );
};

export const KratosField = (props: FormField) => {
    const { t } = useTranslation('account');

    const { type, name, disabled, messages, required, value } = props;

    if (type === 'hidden') {
        return (
            <input
                type="hidden"
                name={name}
                /** @ts-ignore */
                defaultValue={value ?? ''}
            />
        );
    }

    return (
        <div className="form-group">
            <label>{t(`field_${name}`)}</label>
            <input
                className={cn('form-control', {
                    'is-invalid': messages?.some(m => m.type === 'error'),
                })}
                type={type}
                name={name}
                disabled={disabled}
                required={required}
                autoComplete="off"
                /** @ts-ignore */
                defaultValue={value ?? ''}
            />
            {messages &&
                messages.length &&
                messages.map(m => {
                    return (
                        <div
                            key={m.id}
                            className={cn({
                                'invalid-feedback': m.type === 'error',
                            })}
                        >
                            {getLocalizedKratosMessage(t, m)}
                        </div>
                    );
                })}
        </div>
    );
};
