import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import kratos from 'lib/auth/helpers/sdk';
import {
    LoginButton,
    LoginButtons,
    LoginLayout,
    LoginLink,
} from './LoginLayout';
import {
    KratosMessages,
    KratosField,
    useKratosFlow,
} from './kratos/KratosField';

export const LoginRecoverPage = () => {
    const { t } = useTranslation('account');

    const flowHandler = useCallback(async (flowId: string) => {
        const { data } = await kratos.getSelfServiceRecoveryFlow(flowId);
        return data;
    }, []);

    const config = useKratosFlow('recovery/browser', flowHandler);
    if (!config) {
        return null;
    }

    const { link } = config.methods;
    if (!link) {
        throw new Error('Link recovery methods have been conifgured');
    }

    const { config: method } = link;
    // @ts-ignore
    const isSend = config.state === 'sent_email';

    return (
        <LoginLayout title={t('recover_account')}>
            <form noValidate action={method.action} method={method.method}>
                {!config.messages ||
                    (config.messages.length === 0 && (
                        <p className="pb-5">
                            {t('recover_account_description')}
                        </p>
                    ))}
                <KratosMessages messages={config.messages} />
                {!isSend &&
                    method.fields.map(field => {
                        return <KratosField key={field.name} {...field} />;
                    })}
                <LoginButtons>
                    {!isSend && (
                        <LoginButton type="submit">
                            {t('general:submit')}
                        </LoginButton>
                    )}
                    <LoginLink to="/login">{t('back')}</LoginLink>
                </LoginButtons>
            </form>
        </LoginLayout>
    );
};
