import React from 'react';
import SVG from 'react-inlinesvg';

export const Brand = () => {
    return (
        <div className="brand justify-content-center">
            <div className="brand-logo">
                <SVG src="/assets/images/screentrust_logo.svg" />
            </div>
        </div>
    );
};
