import { ApiBase } from '../ApiBase';
import { Policy } from '../dto/Policy';

export class PoliciesApi extends ApiBase {
    public async getAll(): Promise<Policy[]> {
        return await this.get<Policy[]>('policies');
    }

    public async getById(id: string): Promise<Policy | undefined> {
        return await this.get<Policy>(`policies/${id}`);
    }

    public async savePolicy(policy: Policy) {
        if (policy.id) {
            return await this.patch<Policy>(`policies/${policy.id}`, policy);
        }
        return await this.post<Policy>('policies', policy);
    }
}
