import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    DashboardPage,
    PoliciesPage,
    SetupPage,
    LoginErrorPage,
    LoginPage,
    LoginRegistrationPage,
    LoginRecoverPage,
    TestPage,
    NotFoundPage,
    AccountSettingsPage,
} from 'pages';
import { Layout } from './ui';
import { SideMenu, SideMenuItem, SideMenuItemSection } from './ui/SideMenu';
import { useOrganization } from 'lib/context/OrganizationProvider';
import { Secure } from 'lib/auth/Secure';
import { LoginVerifyPage } from 'pages/login/LoginVerifyPage';

export const Menu = () => {
    const { t } = useTranslation();
    const { organization } = useOrganization();

    return (
        <SideMenu>
            {organization && (
                <>
                    <SideMenuItemSection
                        label={t('menu_section_organization')}
                    />
                    <SideMenuItem
                        to={`/dashboard`}
                        icon="/assets/images/svg/icons/Shopping/Chart-line1.svg"
                        label={t('menu_dashboard')}
                    />
                    <SideMenuItem
                        to={`/setup`}
                        icon="/assets/images/svg/icons/Devices/Laptop-macbook.svg"
                        label={t('menu_setup')}
                    />
                    <SideMenuItem
                        to={`/policies`}
                        icon="/assets/images/svg/icons/General/Clipboard.svg"
                        label={t('menu_policies')}
                    />
                </>
            )}
            <SideMenuItemSection label={t('menu_section_developer')} />
            <SideMenuItem
                to={`/test`}
                icon="/assets/images/svg/icons/Tools/Hummer.svg"
                label={t('menu_testpage')}
            />
        </SideMenu>
    );
};

export const Routes = () => {
    return (
        <Switch>
            <Route path="/login">
                <LoginRoutes />
            </Route>
            <Route path="/error">
                <ErrorRoutes />
            </Route>
            <Route path="*">
                <Layout>
                    <Secure>
                        <Switch>
                            <Route exact path="/">
                                <Redirect to="/dashboard" />
                            </Route>
                            <Route path="/dashboard">
                                <DashboardPage />
                            </Route>
                            <Route path="/setup">
                                <SetupPage />
                            </Route>
                            <Route path="/policies">
                                <PoliciesPage />
                            </Route>
                            <Route path="/test">
                                <TestPage />
                            </Route>
                            <Route path="/account/settings">
                                <AccountSettingsPage />
                            </Route>
                            <Route path="*">
                                <NotFoundPage />
                            </Route>
                        </Switch>
                    </Secure>
                </Layout>
            </Route>
        </Switch>
    );
};

export const ErrorRoutes = () => {
    const match = useRouteMatch();
    const { path } = match;

    return (
        <Switch>
            <Route path={`${path}/404`}>
                <NotFoundPage fullPage />
            </Route>
            <Route path="*">
                <Redirect to="/error/404" />
            </Route>
        </Switch>
    );
};

export const LoginRoutes = () => {
    const match = useRouteMatch();
    const { path } = match;

    return (
        <Switch>
            <Route exact path={path}>
                <LoginPage />
            </Route>
            <Route path={`${path}/verify`}>
                <LoginVerifyPage />
            </Route>
            <Route path={`${path}/error`}>
                <LoginErrorPage />
            </Route>
            <Route path={`${path}/register`}>
                <LoginRegistrationPage />
            </Route>
            <Route path={`${path}/recover`}>
                <LoginRecoverPage />
            </Route>
            <Route path="*">
                <Redirect to="/error/404" />
            </Route>
        </Switch>
    );
};
