import React, {
    PropsWithChildren,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { AxiosError } from 'axios';
import kratosSdk, { initiateFlowRedirect } from './helpers/sdk';
import { Session as KratosSession } from '@ory/kratos-client';

import { SessionContext } from './Session';
import { useApi } from 'data/hooks';
import { SessionApi, UserInfo } from 'data/api/cloud';

export const SessionProvider = ({ children }: PropsWithChildren<{}>) => {
    const [sessionContext, setSessionContext] = useState<
        KratosSession | undefined
    >();
    const [isLoadingKratosSession, setIsLoadingKratosSession] = useState(true);

    const syncSession = useCallback(async () => {
        try {
            const response = await kratosSdk.whoami();

            setSessionContext(response.data);
        } catch (err) {
            const axiosError = err as AxiosError;
            if (axiosError.response?.status === 401) {
                console.debug('Session is not authenticated:', axiosError);
            } else {
                console.error(axiosError);
            }

            setSessionContext(undefined);
        }

        setIsLoadingKratosSession(false);
    }, [setSessionContext]);

    useEffect(() => {
        syncSession();
    }, [syncSession]);

    const sessionApi = useApi(SessionApi);
    const [userInfo, setUserInfo] = useState<{
        isLoading: boolean;
        data?: UserInfo;
    } | null>({
        isLoading: true,
    });
    useEffect(() => {
        const effect = async () => {
            if (!sessionContext) {
                setUserInfo({
                    isLoading: false,
                    data: undefined,
                });
            } else {
                const info = await sessionApi.getUserInfo();

                setUserInfo({
                    isLoading: false,
                    data: info,
                });
            }
        };
        effect();
    }, [sessionApi, sessionContext]);

    if (isLoadingKratosSession || userInfo?.isLoading) {
        return null;
    }

    return (
        <SessionContext.Provider
            value={{
                session: sessionContext,
                userInfo: userInfo?.data,
                isAuthenticated: !!sessionContext,
                logout: () => {
                    initiateFlowRedirect('browser/flows/logout');
                },
            }}
        >
            {children}
        </SessionContext.Provider>
    );
};
