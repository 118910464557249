import {
    FC,
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useCallback,
} from 'react';

import { SplashScreen } from 'components/ui/SplashScreen';
import { useApi, useOrganizationQuery } from 'data/hooks';
import { Organization, OrganizationsApi } from 'data/api/cloud';
import { useSession } from 'lib/auth/Session';
import { useLocalstorageState } from 'rooks';

interface OrganizationConnectionContextData {
    organizationId?: string;
    organization?: Organization;
    switchOrganization: (orgId: string) => void;
}

const OrganizationContext = createContext<OrganizationConnectionContextData>({
    switchOrganization: () => {},
});

export const useOrganization = () => {
    return useContext(OrganizationContext);
};

export const OrganizationProvider: FC<PropsWithChildren<{}>> = ({
    children,
}) => {
    const { userInfo } = useSession();

    const [organizationId, setOrganizationId] = useLocalstorageState<
        string | null
    >('organizationId', null);

    const switchOrganization = useCallback(
        (id: string) => {
            setOrganizationId(id);
        },
        [setOrganizationId]
    );

    const api = useApi(OrganizationsApi);

    useEffect(() => {
        if (userInfo && !organizationId) {
            const effect = async () => {
                const organizations = await api.getAll();
                if (organizations) {
                    const defaultOrgId = userInfo.defaultOrganization?.id;

                    // Find the default organization
                    let org = organizations.find(o => o.id === defaultOrgId);
                    if (!org) {
                        // Default not found. Use the first one.
                        org = organizations.values().next().value;
                    }

                    setOrganizationId(org?.id ?? null);
                }
            };
            effect();
        }
    }, [userInfo, api, setOrganizationId, organizationId, switchOrganization]);

    const { isLoading, data: organization } = useOrganizationQuery(
        organizationId ?? undefined,
        {
            useErrorBoundary: false,
        }
    );

    if (isLoading) {
        return <SplashScreen />;
    }

    return (
        <OrganizationContext.Provider
            value={{
                organizationId: organizationId ?? undefined,
                organization: organization ?? undefined,
                switchOrganization,
            }}
        >
            {children}
        </OrganizationContext.Provider>
    );
};
