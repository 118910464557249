import React, { FC } from 'react';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export interface FormButtonsProps {
    isSubmitting: boolean;
    allowCancel: boolean;
}

export const FormButtons: FC<FormButtonsProps> = ({
    isSubmitting,
    allowCancel = false,
}) => {
    const { t } = useTranslation();
    return (
        <Row>
            <Col md={{ offset: 3, span: 9 }}>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && (
                        <Spinner
                            as="span"
                            className="mr-2"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                    {t('save')}
                </Button>
                {allowCancel && (
                    <Button
                        variant="secondary"
                        type="reset"
                        disabled={isSubmitting}
                    >
                        {t('cancel')}
                    </Button>
                )}
            </Col>
        </Row>
    );
};
