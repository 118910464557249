import { OrganizationErrorBoundary } from 'components/OrganizationErrorBoundary';
import { PageLoader } from 'components/PageLoader';
import React, { PropsWithChildren, ReactNode } from 'react';
import { Toasts } from '.';

export interface PageProps {
    subHeader?: ReactNode;
    isLoading?: boolean;
}

export const Page = ({
    isLoading = false,
    subHeader,
    children,
}: PropsWithChildren<PageProps>) => {
    return (
        <>
            {subHeader}
            <div className="d-flex flex-column-fluid h-100">
                <div className="container h-100">
                    {isLoading && <PageLoader />}
                    {!isLoading && <>{children}</>}
                </div>
            </div>
        </>
    );
};

export const OrganizationPage = ({
    children,
    ...props
}: PropsWithChildren<PageProps>) => {
    return (
        <Page {...props}>
            <OrganizationErrorBoundary>{children}</OrganizationErrorBoundary>
        </Page>
    );
};
