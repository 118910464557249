import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Row, Col, Container, Alert, Button } from 'react-bootstrap';

import kratos from 'lib/auth/helpers/sdk';
import { Page, SubHeader } from 'components/ui';
import {
    KratosField,
    KratosMessages,
    useKratosFlow,
} from 'pages/login/kratos/KratosField';

export const AccountSettingsPage = () => {
    const { t } = useTranslation('account');
    const history = useHistory();

    const flowHandler = useCallback(async (flowId: string) => {
        const { data } = await kratos.getSelfServiceSettingsFlow(flowId);
        return data;
    }, []);

    const config = useKratosFlow('settings/browser', flowHandler);

    // @ts-ignore
    const state = config?.state;
    useEffect(() => {
        if (state === 'success') {
            history.push('/');
        }
    }, [history, state]);

    if (!config) {
        return null;
    }

    const methods = Object.values(config.methods).reverse();

    return (
        <Page
            subHeader={
                <SubHeader>
                    <SubHeader.Title title={t('header_account_settings')} />
                </SubHeader>
            }
        >
            <Container>
                {config.messages && config.messages.length !== 0 && (
                    <Alert variant="primary">
                        {config.messages.map(m => {
                            return <span key={m.id}>{m.text}</span>;
                        })}
                    </Alert>
                )}

                <Row>
                    {methods.map(({ method, config: methodConfig }) => {
                        return (
                            <Col key={method} xs={12} md={6}>
                                <Card className="card-custom">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3 className="card-label">
                                                {t(`account_config_${method}`)}
                                            </h3>
                                        </div>
                                    </div>
                                    <Card.Body>
                                        <form
                                            noValidate
                                            action={methodConfig.action}
                                            method={methodConfig.method}
                                        >
                                            <KratosMessages
                                                messages={methodConfig.messages}
                                            />
                                            {methodConfig.fields.map(field => {
                                                return (
                                                    <KratosField
                                                        key={field.name}
                                                        {...field}
                                                    />
                                                );
                                            })}
                                            <Button
                                                variant="primary"
                                                type="submit"
                                            >
                                                {t('general:submit')}
                                            </Button>
                                        </form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </Page>
    );
};
