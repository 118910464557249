import { createContext, useContext } from 'react';
import { Session as KratosSession } from '@ory/kratos-client';

import { UserInfo } from 'data/api/cloud';

export interface SessionContextType {
    session?: KratosSession;
    userInfo?: UserInfo;
    isAuthenticated: boolean;
    logout: () => void;
}

export const SessionContext = createContext<SessionContextType>({
    isAuthenticated: false,
    logout: () => {},
});

export const useSession = () => {
    return useContext(SessionContext);
};
