import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import kratos from 'lib/auth/helpers/sdk';
import { FullPageError } from 'components/Error';

export const LoginErrorPage = () => {
    const location = useLocation();
    const history = useHistory();

    const [errors, setErrors] = useState<any[] | undefined>();

    const query = qs.parse(location.search.replace('?', ''));
    const errorId = query.error as string | null;

    const loadError = useCallback(async () => {
        if (errorId) {
            const { data: error } = await kratos.getSelfServiceError(errorId);

            // @ts-ignore
            setErrors(error.errors);
        }
    }, [errorId]);

    useEffect(() => {
        loadError();
    }, [loadError]);

    if (!errors) {
        return null;
    }

    return (
        <FullPageError
            onFixClick={() => {
                history.push('/login');
            }}
        >
            {errors.map(e => {
                return <p key={e.message}>{e.reason}</p>;
            })}
        </FullPageError>
    );
};
