import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

import { forwardRef, MouseEventHandler, PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSession } from 'lib/auth/Session';
import { Link } from 'react-router-dom';
import { useOrganizationsQuery } from 'data/hooks';
import { useOrganization } from 'lib/context/OrganizationProvider';

interface Props {
    onClick: MouseEventHandler;
}

export const DropdownTopbarItemToggler = forwardRef<
    HTMLDivElement,
    PropsWithChildren<Props>
>(({ children, onClick }, ref) => {
    return (
        <div
            ref={ref}
            className="topbar-item"
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </div>
    );
});

export const AccountTopBarItem = () => {
    const { t } = useTranslation();
    const { session, logout } = useSession();

    if (!session) {
        return null;
    }

    const traits = session.identity.traits;
    // @ts-ignore
    const name = `${traits.name?.first} ${traits.name?.last}`.trim();

    return (
        <div className="topbar-item">
            <Dropdown alignRight drop="down">
                <Dropdown.Toggle as={DropdownTopbarItemToggler}>
                    <div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2">
                        <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                            {t('hi')},
                        </span>
                        <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                            {name}
                        </span>
                        <span className="symbol symbol-lg-35 symbol-25 symbol-light-success">
                            <span className="symbol-label font-size-h5 font-weight-bold">
                                {name?.substr(0, 1).toUpperCase()}
                            </span>
                        </span>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-md">
                    <Dropdown.Item as={Link} to={'/account/settings'}>
                        <span className="fa-icon">
                            <FontAwesomeIcon icon={faUser} />
                        </span>
                        {t('account_settings')}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                        onClick={() => {
                            logout();
                        }}
                    >
                        <span className="fa-icon">
                            <FontAwesomeIcon icon={faSignOutAlt} />
                        </span>
                        {t('logout')}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export const OrgSelectionTopBarItem = () => {
    const { t } = useTranslation();
    const { organization, switchOrganization } = useOrganization();
    const { data: organizations } = useOrganizationsQuery();

    const showOrgSelection =
        organizations && (organizations.length > 1 || !organization);

    if (!showOrgSelection) {
        return null;
    }

    return (
        <div className="topbar-item mr-3">
            <Select
                className="select-location"
                options={organizations}
                placeholder={t('select_location')}
                defaultValue={organization}
                getOptionValue={org => org.id}
                getOptionLabel={org => org.name}
                onChange={org => {
                    if (org) {
                        switchOrganization(org.id);
                    }
                }}
            />
        </div>
    );
};

export const HeaderMenu = () => {
    return (
        <div className="header-menu header-menu-mobile header-menu-layout-default">
            <ul className="menu-nav">
                <li className="menu-item"></li>
            </ul>
        </div>
    );
};

export const Header = () => {
    return (
        <div className="header header-fixed">
            <div className="container-fluid d-flex align-items-stretch justify-content-between">
                <div className="header-menu-wrapper header-menu-wrapper-left">
                    <div className="header-menu header-menu-mobile header-menu-layout-default">
                        <HeaderMenu />
                    </div>
                </div>

                <div className="topbar d-flex flex-columns">
                    <OrgSelectionTopBarItem />
                    <AccountTopBarItem />
                </div>
            </div>
        </div>
    );
};
