import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Row,
} from 'react-bootstrap';

import { OrganizationPage, SubHeader, Svg } from '../../components/ui';
import { useOrganization } from 'lib/context/OrganizationProvider';
import { useSetupConfig } from 'data/hooks/ApiQuery';

export const SetupPage = () => {
    const { t } = useTranslation('setup');
    const { organizationId } = useOrganization();

    const { isLoading, data: config } = useSetupConfig();
    const [userId, setUserId] = useState<string | undefined>();

    const pacFileUrl = useMemo(() => {
        if (config) {
            return config.pacFileUrl
                .replace('{orgId}', organizationId ?? '')
                .replace('{userId}', userId ?? '');
        }
        return '';
    }, [organizationId, userId, config]);

    const proxyCertUrl = new URL(
        'setup/proxy.crt',
        process.env.REACT_APP_API_URL
    ).toString();

    return (
        <OrganizationPage
            isLoading={isLoading}
            subHeader={
                <SubHeader>
                    <SubHeader.Title title={t('header_setup')} />
                </SubHeader>
            }
        >
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="card-custom">
                            <div className="card-header">
                                <div className="card-title">
                                    <h3 className="card-label">
                                        {t(`pac_file`)}
                                    </h3>
                                </div>
                            </div>
                            <Card.Body>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={3}>
                                        {t('user_id')}
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control
                                            type="text"
                                            onChange={e =>
                                                setUserId(e.target.value)
                                            }
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={3}>
                                        {t('pac_file_url')}
                                    </Form.Label>
                                    <Col sm={9}>
                                        <InputGroup>
                                            <Form.Control
                                                disabled
                                                type="text"
                                                value={pacFileUrl}
                                            />
                                            <InputGroup.Append>
                                                <Button
                                                    variant="secondary"
                                                    onClick={async () => {
                                                        await navigator.clipboard.writeText(
                                                            pacFileUrl
                                                        );

                                                        toast.success(
                                                            t(
                                                                'pac_clipboard_copied'
                                                            ) as string
                                                        );
                                                    }}
                                                >
                                                    <Svg
                                                        src="assets/images/svg/icons/General/Clipboard.svg"
                                                        variant="dark"
                                                        className="mr-0"
                                                    />
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={3}>
                                        {t('proxy_certificate')}
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Button
                                            variant="primary"
                                            href={proxyCertUrl}
                                            target="_blank"
                                        >
                                            {t('download')}
                                        </Button>
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </OrganizationPage>
    );
};
