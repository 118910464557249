export enum FilterCategory {
    Abuse = 'abuse',
    Ads = 'ads',
    Adult = 'adult',
    Crypto = 'crypto',
    Drugs = 'drugs',
    Entertainment = 'entertainment',
    Fraud = 'fraud',
    Gambling = 'gambling',
    SecurityRisk = 'security-risk',
    SocialMedia = 'social-media',
    Worrisome = 'worrisome',
}

export interface PolicyContent {
    blockedCategories: FilterCategory[];
}

export interface Policy {
    id?: string;
    content: PolicyContent;
}
