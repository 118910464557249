import { OrganizationPage, SubHeader } from '../../components/ui';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'lib/context/OrganizationProvider';

export const DashboardPage = () => {
    const { t } = useTranslation('dashboard');

    const { organization } = useOrganization();

    return (
        <OrganizationPage
            subHeader={
                <SubHeader>
                    <SubHeader.Title title={t('header')} />
                </SubHeader>
            }
        >
            <div>Dashboard</div>
            <div>Current organzation: {organization?.name}</div>
        </OrganizationPage>
    );
};
