import { GenericError } from '@ory/kratos-client';
import { Message } from '@ory/kratos-client';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';

export const getLocalizedKratosMessage = (t: TFunction, msg: Message) => {
    switch (msg.id) {
        case 4000001:
            return t('error_required');
        case 4000006:
            return t('error_invalid_credentials');
        case 1060001:
            return t('account_recovered_message');
        default:
            return msg.text;
    }
};

export function handleFormSubmitError<T>(
    setConfig: (p: T) => void,
    initialize: () => void,
    logout?: () => void
) {
    return (err: AxiosError) => {
        if (err.response) {
            switch (err.response.status) {
                case 400:
                    if (typeof err.response.data.error === 'object') {
                        console.warn(err.response.data);

                        const ge: GenericError = err.response.data;

                        // showMessage({
                        //     message: `${ge.error?.message}: ${ge.error?.reason}`,
                        //     type: 'danger',
                        // });

                        return Promise.resolve();
                    }

                    console.warn('Form validation failed:', err.response.data);
                    setConfig(err.response.data);
                    return Promise.resolve();
                case 404:
                case 410:
                    // This happens when the flow is, for example, expired or was deleted.
                    // We simply re-initialize the flow if that happens!
                    console.warn('Flow could not be found, reloading page.');
                    initialize();
                    return Promise.resolve();
                case 403:
                case 401:
                    if (!logout) {
                        console.error(
                            `Received unexpected 401/403 status code: `,
                            err,
                            err.response.data
                        );
                        return Promise.resolve();
                    }

                    // This happens when the privileged session is expired but the user tried
                    // to modify a privileged field (e.g. change the password).
                    console.warn(
                        'The server indicated that this action is not allowed for you. The most likely cause of that is that you modified a privileged field (e.g. your password) but your ORY Kratos Login Session is too old.'
                    );
                    // showMessage({
                    //     message:
                    //         'Please re-authenticate before making these changes.',
                    //     type: 'warning',
                    // });
                    logout();
                    return Promise.resolve();
            }
        }

        console.error(err, err.response?.data);
        return Promise.resolve();
    };
}
