import { useTranslation } from 'react-i18next';

import { Error, FullPageError } from 'components/Error';
import { Page, SubHeader } from 'components/ui';

export const NotFoundPage = ({ fullPage = false }: { fullPage?: boolean }) => {
    const { t } = useTranslation();

    const Component = fullPage ? FullPageError : Error;

    return (
        <Component
            title={t('error_page_not_found')}
            fixTitle={t('go_to_home')}
            fixLinkTo="/"
        >
            {t('error_page_not_found_description')}
        </Component>
    );
};
