import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Error } from './Error';
import { useOrganization } from 'lib/context/OrganizationProvider';

export const OrganizationErrorBoundary = ({
    children,
}: PropsWithChildren<{}>) => {
    const { t } = useTranslation();
    const { organization } = useOrganization();

    if (!organization) {
        return (
            <Error title={t('error_organization_not_found')}>
                {t('error_organization_not_found_description')}
            </Error>
        );
    }

    return <>{children}</>;
};
