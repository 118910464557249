import React from 'react';
import { useField } from 'formik';
import { Form, Col, Row } from 'react-bootstrap';
import Select, { GroupTypeBase, OptionTypeBase, Props } from 'react-select';
import cn from 'classnames';

export interface FormSelectExProps {
    label: string;
    description?: string;
}

export const FormSelectEx = <
    OptionType extends OptionTypeBase,
    IsMulti extends boolean = false,
    GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
    label,
    description,
    name,
    ...props
}: Props<OptionType, IsMulti, GroupType>) => {
    const [field, meta, helpers] = useField(name!);
    const { onChange, onBlur, value, ...fieldRest } = field;
    const hasError = meta.error !== undefined;

    const showError = meta.touched && hasError;

    return (
        <Form.Group as={Row}>
            <Form.Label column sm={3}>
                {label}
            </Form.Label>
            <Col sm={9}>
                <Select
                    multiple={false}
                    className={cn('react-select', { 'is-invalid': showError })}
                    {...fieldRest}
                    {...props}
                    value={value ?? null}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    onChange={(option: any) => {
                        helpers.setValue(option);
                    }}
                    onBlur={e => {
                        helpers.setTouched(true);
                    }}
                />
                {!showError && description && (
                    <Form.Text>{description}</Form.Text>
                )}
                {showError && (
                    <Form.Control.Feedback type="invalid">
                        {meta.error}
                    </Form.Control.Feedback>
                )}
            </Col>
        </Form.Group>
    );
};
