import React, { PropsWithChildren, ReactNode } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Svg, SvgVariantType } from './ui/Svg';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';

interface ErrorProps {
    title?: string;
    error?: string | Error;
    icon?: string;
    iconVariant?: SvgVariantType;
    identifier?: string | null;
    fixTitle?: string;
    fixLinkTo?: string;
    onFixClick?: () => void;
}

export const ErrorMsg = ({
    title,
    error,
    icon = '/assets/images/svg/icons/Code/Error-circle.svg',
    iconVariant = 'danger',
    children,
    identifier = null,
    fixTitle,
    fixLinkTo,
    onFixClick,
}: PropsWithChildren<ErrorProps> & React.HTMLAttributes<HTMLDivElement>) => {
    const { t } = useTranslation();

    let content: any;
    if (children) {
        content = children;
    } else {
        if (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response) {
                const { response } = axiosError;

                const apiErrorMsg = response.data?.error;
                if (apiErrorMsg) {
                    content = <p>{t(`error_fetch_${apiErrorMsg}`)}</p>;
                } else {
                    content = (
                        <p>
                            {t(`error_fetch_general`, {
                                statusCode: response.status,
                            })}
                        </p>
                    );
                }
            } else if (typeof error === 'string') {
                content = error;
            }

            if (!content) {
                content = t('error_general_description');
            }
        }
    }

    let fixButton: ReactNode | null = null;
    if (fixLinkTo) {
        fixButton = (
            <Button
                as={Link}
                variant="primary"
                className="mt-5 min-wi"
                to={fixLinkTo}
            >
                {fixTitle ?? t('error_retry')}
            </Button>
        );
    } else if (onFixClick) {
        fixButton = (
            <Button variant="primary" className="mt-5" onClick={onFixClick}>
                {fixTitle ?? t('error_retry')}
            </Button>
        );
    }

    return (
        <>
            <Svg className="mb-5" variant={iconVariant} size="5x" src={icon} />
            <h3>{title ?? t('error_page_header')}</h3>
            {content}
            {identifier && (
                <>
                    <p>{t('error_page_message_identifier')}</p>
                    <p>
                        <strong>{identifier}</strong>
                    </p>
                </>
            )}
            {fixButton && <p>{fixButton}</p>}
        </>
    );
};

export const Error = ({
    className,
    ...props
}: PropsWithChildren<ErrorProps> & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <Row className={cn('mt-15', className)}>
            <Col sm={{ offset: 3, span: 6 }} className="text-center">
                <ErrorMsg {...props} />
            </Col>
        </Row>
    );
};

export const FullPageError = ({
    className,
    ...props
}: PropsWithChildren<ErrorProps> & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <Container>
            <Row className={cn('align-items-center', 'h-100', className)}>
                <Col sm={{ offset: 3, span: 6 }} className="text-center">
                    <Card className="mt-20 p-15">
                        <ErrorMsg {...props} />
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};
