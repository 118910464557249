import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const PageLoader = ({ label }: { label?: string }) => {
    const { t } = useTranslation('general');

    return (
        <div className="h-100">
            <div className="row h-100 justify-content-center align-items-center">
                <div className="text-center">
                    <Spinner
                        className="mb-3"
                        animation="border"
                        variant="primary"
                    />
                    <div>
                        <h3>{label ?? t('loading')}</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};
