import { Organization } from '..';
import { ApiBase } from '../ApiBase';

export class OrganizationsApi extends ApiBase {
    constructor() {
        super(null);
    }

    public async getAll(): Promise<Organization[]> {
        return await this.get<Organization[]>(`organizations`);
    }

    public async getById(orgId: string): Promise<Organization | undefined> {
        return await this.get<Organization>(`organizations/${orgId}`);
    }
}
