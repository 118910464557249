import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { BsPrefixRefForwardingComponent } from 'react-bootstrap/esm/helpers';

import { Link } from 'react-router-dom';

interface SubHeaderTitleProps {
    title: string | undefined;
}

const SubHeaderTitle: FC<PropsWithChildren<SubHeaderTitleProps>> = ({
    title = undefined,
    children,
}) => {
    let content: ReactNode = title;
    if (!title) {
        content = children;
    }
    return (
        <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">{content}</h5>
    );
};

SubHeaderTitle.defaultProps = {
    title: undefined,
};

export const SubHeaderBreadcrumbItem = ({
    to,
    label,
}: {
    to: string;
    label: string;
}) => {
    return (
        <li className="breadcrumb-item text-muted">
            <Link to={to} className="text-muted">
                {label}
            </Link>
        </li>
    );
};

export const SubHeaderBreadcrumbs = ({ children }: PropsWithChildren<{}>) => {
    return (
        <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
            {children}
        </ul>
    );
};

const SubHeaderSeparator = () => {
    return (
        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
    );
};

export interface SubHeaderButtonProps extends ButtonProps {}

type SubHeaderButtonType = BsPrefixRefForwardingComponent<
    'button',
    SubHeaderButtonProps
>;

const SubHeaderButton: SubHeaderButtonType = ({
    children,
    ...props
}: PropsWithChildren<SubHeaderButtonProps>) => {
    return (
        <Button
            size="sm"
            className="btn-light-primary font-weight-bolder ml-2"
            {...props}
        >
            {children}
        </Button>
    );
};

type SubHeaderType = FC<PropsWithChildren<SubHeaderProps>> & {
    Title: typeof SubHeaderTitle;
    Separator: typeof SubHeaderSeparator;
    Button: typeof SubHeaderButton;
    Breadcrumbs: typeof SubHeaderBreadcrumbs;
    BreadcrumbItem: typeof SubHeaderBreadcrumbItem;
};

interface SubHeaderProps {
    actions?: ReactNode;
}

export const SubHeader: SubHeaderType = (({ children, actions }) => {
    return (
        <div className="subheader py-2 py-lg-4 subheader-solid">
            <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <div className="d-flex align-items-center flex-wrap mr-2">
                    <div className="d-flex align-items-baseline flex-wrap mr-5">
                        {children}
                    </div>
                </div>
                <div className="d-flex align-items-center">{actions}</div>
            </div>
        </div>
    );
}) as SubHeaderType;

SubHeader.Title = SubHeaderTitle;
SubHeader.Separator = SubHeaderSeparator;
SubHeader.Button = SubHeaderButton;
SubHeader.Breadcrumbs = SubHeaderBreadcrumbs;
SubHeader.BreadcrumbItem = SubHeaderBreadcrumbItem;
