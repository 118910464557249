import { Configuration, PublicApi } from '@ory/kratos-client';
import axiosFactory from 'axios';
import { resilience } from '../../axios';

const axios = axiosFactory.create();
resilience(axios);

const canonicalize = (url: string = '') => url.replace(/\/+$/, '');

export const kratosUrl = canonicalize(process.env.REACT_APP_AUTH_URL);

export const initiateFlowRedirect = (flowPath: string, redirect?: string) => {
    window.location.href = `${kratosUrl}/self-service/${flowPath}`;
};

export const kratosSdk = (token?: string) =>
    new PublicApi(
        new Configuration({
            basePath: kratosUrl,
            baseOptions: {
                withCredentials: true,
                timeout: 5000,
            },
        }),
        '',
        // Ensure that we are using the axios client with retry.
        // @ts-ignore
        axios
    );

export default kratosSdk();
