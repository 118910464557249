import { PropsWithChildren, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSession } from './Session';

export const Secure = ({ children }: PropsWithChildren<{}>) => {
    const history = useHistory();
    const { isAuthenticated } = useSession();

    useEffect(() => {
        if (!isAuthenticated) {
            history.push('/login');
        }
    }, [history, isAuthenticated]);

    if (!isAuthenticated) {
        return null;
    }

    return <>{children}</>;
};
