import { SetupApi } from 'data/api/cloud/clients/SetupApi';
import { UseQueryOptions } from 'react-query';
import { Session as KratosSession } from '@ory/kratos-client';

import { useApiQuery, useOrganizationApiQuery } from '.';
import {
    Organization,
    OrganizationsApi,
    PoliciesApi,
    SessionApi,
} from '../api/cloud';

export function useUserInfoQuery(session?: KratosSession) {
    return useApiQuery(['me'], SessionApi, async api => {
        if (!session) {
            return undefined;
        }
        return await api.getUserInfo();
    });
}

export function useOrganizationQuery(
    orgId?: string,
    options?: UseQueryOptions<
        Organization | null | undefined,
        unknown,
        Organization | null | undefined
    >
) {
    return useApiQuery(
        ['organizations', orgId],
        OrganizationsApi,
        async api => {
            if (!orgId) {
                return undefined;
            }

            return await api.getById(orgId);
        },
        options
    );
}

export function useOrganizationsQuery() {
    return useApiQuery(['organizations'], OrganizationsApi, async api => {
        return await api.getAll();
    });
}

export function usePoliciesQuery() {
    return useOrganizationApiQuery(['policies'], PoliciesApi, async api => {
        return await api.getAll();
    });
}

export function useSetupConfig() {
    return useOrganizationApiQuery(['setup/config'], SetupApi, async api => {
        return await api.getConfig();
    });
}
