import React, {
    FC,
    createContext,
    useContext,
    useState,
    useEffect,
    PropsWithChildren,
} from 'react';

const SplashScreenContext = createContext<
    React.Dispatch<React.SetStateAction<number>>
>(count => {
    return count;
});

export const SplashScreenProvider: FC<PropsWithChildren<{}>> = ({
    children,
}) => {
    const [count, setCount] = useState(0);
    let visible = count > 0;

    useEffect(() => {
        const splashScreen = document.getElementById('splash-screen');

        // Show it
        if (splashScreen && visible) {
            splashScreen.classList.remove('hidden');

            return () => {
                splashScreen.classList.add('hidden');
            };
        }

        // Hide it
        let timeout: any;
        if (splashScreen && !visible) {
            timeout = setTimeout(() => {
                splashScreen.classList.add('hidden');
            }, 2000);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [visible]);

    return (
        <SplashScreenContext.Provider value={setCount}>
            {children}
        </SplashScreenContext.Provider>
    );
};

export const SplashScreen = ({ visible = true }) => {
    const setCount = useContext(SplashScreenContext);

    useEffect(() => {
        if (!visible) {
            return;
        }

        setCount(prev => {
            return prev + 1;
        });

        return () => {
            setCount(prev => {
                return prev - 1;
            });
        };
    }, [setCount, visible]);

    return null;
};
