import cn from 'classnames';
import { ClassValue } from 'classnames/types';
import SVG from 'react-inlinesvg';

export type SvgVariantType =
    | 'light'
    | 'secondary'
    | 'success'
    | 'info'
    | 'primary'
    | 'warning'
    | 'danger'
    | 'white'
    | 'dark'
    | 'dark-75'
    | 'dark-50'
    | 'dark-25';

export type SvgSizeType =
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2x'
    | '3x'
    | '4x'
    | '5x'
    | '6x'
    | '7x'
    | '8x'
    | '9x'
    | '10x';

export const Svg = ({
    variant = 'dark-50',
    size = 'sm',
    className,
    width,
    height,
    src,
}: {
    variant?: SvgVariantType;
    size?: SvgSizeType;
    className?: ClassValue;
    width?: string | number;
    height?: string | number;
    src: string;
}) => {
    return (
        <div
            className={cn(
                'svg-icon',
                `svg-icon-${variant}`,
                `svg-icon-${size}`,
                className
            )}
        >
            <SVG width={width} height={height} src={src} />
        </div>
    );
};
