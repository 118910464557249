import { ApiBase } from '../ApiBase';

export interface SetupConfig {
    pacFileUrl: string;
    proxyCertificate: string;
}

export class SetupApi extends ApiBase {
    public async getConfig(): Promise<SetupConfig> {
        return await this.get<SetupConfig>('setup/config');
    }
}
