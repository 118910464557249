import './config/i18n';
import './assets/sass/index.scss';
import './assets/plugins/flaticon/flaticon.css';
import './assets/plugins/flaticon2/flaticon.css';

import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { Routes } from 'components/Routes';
import { FullPageError } from 'components/Error';
import { OrganizationProvider } from 'lib/context/OrganizationProvider';
import { useSession } from 'lib/auth/Session';
import { SessionProvider } from 'lib/auth/SessionProvider';

export const SentryUserHook = () => {
    const { session } = useSession();

    // @ts-ignore
    const email = session?.identity?.traits.email;

    useEffect(() => {
        Sentry.setUser({ email });
    }, [email]);

    return null;
};

const queryClient = new QueryClient();

const App = () => {
    return (
        <>
            <Sentry.ErrorBoundary
                onReset={() => window.location.reload()}
                fallback={({ eventId, error, resetError }) => {
                    return (
                        <FullPageError
                            error={error}
                            identifier={eventId}
                            onFixClick={() => resetError()}
                        />
                    );
                }}
            >
                <SessionProvider>
                    <SentryUserHook />
                    <QueryClientProvider client={queryClient}>
                        <Router>
                            <OrganizationProvider>
                                <Routes />
                            </OrganizationProvider>
                        </Router>
                    </QueryClientProvider>
                </SessionProvider>
            </Sentry.ErrorBoundary>
        </>
    );
};

export default App;
