import React from 'react';
import { Toast } from 'react-bootstrap';
import { toast, useToaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export const Toasts = () => {
    const { t } = useTranslation();
    const { visibleToasts, handlers } = useToaster();
    const { startPause, endPause } = handlers;

    return (
        <div
            aria-live="polite"
            aria-atomic="true"
            style={{
                position: 'relative',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    zIndex: 999,
                    top: 20,
                    right: 20,
                }}
                onMouseEnter={startPause}
                onMouseLeave={endPause}
            >
                {visibleToasts.map(item => (
                    <Toast
                        key={item.id}
                        role={item.role}
                        aria-live={item.ariaLive}
                        style={{
                            minWidth: 300,
                        }}
                        onClose={() => {
                            toast.dismiss(item.id);
                        }}
                    >
                        <Toast.Header>
                            <strong className="mr-auto">
                                {t(`toast_header_${item.type}`)}
                            </strong>
                        </Toast.Header>
                        <Toast.Body>{item.message}</Toast.Body>
                    </Toast>
                ))}
            </div>
        </div>
    );
};
