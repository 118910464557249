import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import kratos from 'lib/auth/helpers/sdk';
import {
    LoginButton,
    LoginButtons,
    LoginLayout,
    LoginLink,
} from './LoginLayout';
import {
    KratosMessages,
    KratosField,
    useKratosFlow,
} from './kratos/KratosField';

export const LoginPage = () => {
    const { t } = useTranslation('account');

    const flowHandler = useCallback(async (flowId: string) => {
        const { data } = await kratos.getSelfServiceLoginFlow(flowId);
        return data;
    }, []);

    const config = useKratosFlow('login/browser', flowHandler);

    if (!config) {
        return null;
    }

    const { password } = config.methods;
    const { config: passwordConfig } = password;
    const { messages } = passwordConfig;

    return (
        <LoginLayout>
            <form
                noValidate
                action={passwordConfig.action}
                method={passwordConfig.method}
            >
                <KratosMessages messages={messages} />
                {passwordConfig.fields.map(field => {
                    return <KratosField key={field.name} {...field} />;
                })}
                <LoginButtons>
                    <LoginButton type="submit">{t('sign_in')}</LoginButton>
                    <LoginLink to="/login/register">
                        {t('create_an_account')}
                    </LoginLink>
                    <LoginLink to="/login/recover">
                        {t('forgot_password')}?
                    </LoginLink>
                </LoginButtons>
            </form>
        </LoginLayout>
    );
};
