import { UserInfo } from '../dto/UserInfo';
import { ApiBase } from '../ApiBase';

export class SessionApi extends ApiBase {
    constructor() {
        super(null);
    }

    public async getUserInfo(): Promise<UserInfo> {
        return await this.get<UserInfo>('me');
    }
}
