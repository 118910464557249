import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Card } from 'react-bootstrap';

import { OrganizationPage, SubHeader } from '../../components/ui';
import { Formik } from 'formik';
import { usePoliciesQuery, useOrganizationApi } from 'data/hooks';
import { PageLoader } from 'components/PageLoader';
import { FormButtons, FormSelectEx } from 'components/formik';
import { FilterCategory, PoliciesApi } from 'data/api/cloud';

export const PoliciesPage = () => {
    const { t } = useTranslation('policies');

    const categoryOptions = useMemo(() => {
        return Object.values(FilterCategory).map(value => {
            return { label: t(`category_${value}`), value: value };
        });
    }, [t]);

    const api = useOrganizationApi(PoliciesApi);

    const { isLoading, data: policies, refetch } = usePoliciesQuery();
    if (isLoading) {
        return <PageLoader label={t('loading_policies')} />;
    }

    const firstPolicy = policies!.length > 0 ? policies![0] : null;
    const blockedCategories = firstPolicy?.content?.blockedCategories
        ? categoryOptions.filter(c =>
              firstPolicy.content.blockedCategories.includes(c.value)
          )
        : [];

    return (
        <OrganizationPage
            subHeader={
                <SubHeader>
                    <SubHeader.Title title={t('header_policies')} />
                </SubHeader>
            }
        >
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="card-custom">
                            <div className="card-header">
                                <div className="card-title">
                                    <h3 className="card-label">
                                        {t(`policy`)}
                                    </h3>
                                </div>
                            </div>
                            <Card.Body>
                                <Formik
                                    initialValues={{ blockedCategories }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        api.savePolicy(
                                            Object.assign({}, firstPolicy, {
                                                content: {
                                                    blockedCategories: values.blockedCategories.map(
                                                        // @ts-ignore
                                                        c => c.value
                                                    ),
                                                },
                                            })
                                        );

                                        setSubmitting(false);
                                        refetch();
                                    }}
                                >
                                    {({ isSubmitting, handleSubmit }) => (
                                        <form onSubmit={handleSubmit}>
                                            <FormSelectEx
                                                label={t('blocked_categories')}
                                                name="blockedCategories"
                                                isMulti
                                                options={categoryOptions}
                                            />
                                            <FormButtons
                                                isSubmitting={isSubmitting}
                                                allowCancel={false}
                                            ></FormButtons>
                                        </form>
                                    )}
                                </Formik>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </OrganizationPage>
    );
};
