import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import kratos from 'lib/auth/helpers/sdk';
import {
    LoginButton,
    LoginButtons,
    LoginLayout,
    LoginLink,
} from './LoginLayout';
import {
    KratosMessages,
    KratosField,
    useKratosFlow,
} from './kratos/KratosField';

export const LoginRegistrationPage = () => {
    const { t } = useTranslation('account');

    const flowHandler = useCallback(async (flowId: string) => {
        const { data } = await kratos.getSelfServiceRegistrationFlow(flowId);
        return data;
    }, []);

    const config = useKratosFlow('registration/browser', flowHandler);

    if (!config) {
        return null;
    }

    const { password } = config.methods;
    const { config: registrationConfig } = password;
    const { messages } = registrationConfig;

    return (
        <LoginLayout title={t('create_an_account')}>
            <form
                noValidate
                action={registrationConfig.action}
                method={registrationConfig.method}
            >
                <KratosMessages messages={messages} />
                {registrationConfig.fields.map(field => {
                    return <KratosField key={field.name} {...field} />;
                })}
                <LoginButtons>
                    <LoginButton type="submit">
                        {t('create_account')}
                    </LoginButton>
                    <LoginLink to="/login">{t('back')}</LoginLink>
                </LoginButtons>
            </form>
        </LoginLayout>
    );
};
