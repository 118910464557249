import React, { PropsWithChildren } from 'react';
import SVG from 'react-inlinesvg';

import 'assets/sass/pages/login.scss';
import { Link } from 'react-router-dom';

export const LoginButtons = ({ children }: PropsWithChildren<{}>) => {
    return <div className="text-center pt-2">{children}</div>;
};

export const LoginButton = ({
    type,
    onClick,
    children,
}: PropsWithChildren<{
    type?: 'submit' | 'reset' | 'button';
    onClick?: () => void;
}>) => {
    return (
        <button
            className="btn btn-dark font-weight-bolder font-size-h6 px-8 py-4 my-3"
            type={type}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export const LoginLink = ({
    to,
    children,
}: PropsWithChildren<{ to: string }>) => {
    return (
        <Link
            className="btn btn-link mt-2 d-block text-primary font-size-h6 font-weight-bolder text-hover-primary"
            to={to}
        >
            {children}
        </Link>
    );
};

export const LoginLayout = ({
    title,
    children,
}: PropsWithChildren<{ title?: string; message?: string }>) => {
    return (
        <div className="login d-flex flex-row w-100 justify-content-center">
            <div className="card">
                <div className="logo mx-auto mb-15">
                    <SVG src="/assets/images/screentrust_logo.svg" />
                </div>
                {title && (
                    <div className="text-center pb-5">
                        <h2 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                            {title}
                        </h2>
                    </div>
                )}
                <div>{children}</div>
            </div>
        </div>
    );
};
