import axiosFactory, { Method } from 'axios';

const axios = axiosFactory.create({
    baseURL: process.env.REACT_APP_API_URL,
});

export abstract class ApiBase {
    constructor(private readonly orgId: string | null) {}

    protected async get<T>(
        endpoint: string[] | string,
        params: any = null
    ): Promise<T> {
        return await this.request('get', endpoint, null, params);
    }

    protected async post<T>(
        endpoint: string[] | string,
        data: any
    ): Promise<T> {
        return await this.request('post', endpoint, data);
    }

    protected async patch<T>(
        endpoint: string[] | string,
        data: any
    ): Promise<T> {
        return await this.request('patch', endpoint, data);
    }

    protected async put<T>(endpoint: string[] | string, data: any): Promise<T> {
        return await this.request('put', endpoint, data);
    }

    protected async delete<T>(endpoint: string[] | string): Promise<T> {
        return await this.request('delete', endpoint);
    }

    private async request<T>(
        method: Method,
        path: string[] | string,
        data: any = null,
        params: any = null
    ): Promise<T> {
        let url: string;
        if (Array.isArray(path)) {
            url = this.url(...path).toString();
        } else {
            url = this.url(path).toString();
        }

        const headers: any = {
            'Content-Type': 'application/json',
        };

        if (this.orgId) {
            headers['X-Organization-Id'] = this.orgId;
        }

        const response = await axios.request({
            url,
            method,
            params,
            data,
            headers,
            withCredentials: true,
        });

        console.debug(
            `Api response: ${method} ${url}, status: ${response.status}`
        );

        return response.data as T;
    }

    protected url(...paths: string[]) {
        return paths.map(p => p.replace(/(^\/|\/$)/g, '')).join('/');
    }
}
