import { useMemo } from 'react';
import {
    QueryFunctionContext,
    QueryKey,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query';

import { ApiBase } from '../api/cloud/ApiBase';
import { useOrganization } from '../../lib/context/OrganizationProvider';
import { useSession } from 'lib/auth/Session';

export function useApi<TApi extends ApiBase>(apiType: new () => TApi) {
    return useMemo(() => {
        return new apiType();
    }, [apiType]);
}

export function useApiQuery<
    TApi extends ApiBase,
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData
>(
    queryKey: QueryKey,
    apiType: new () => TApi,
    handler: (
        api: TApi,
        context: QueryFunctionContext<any>
    ) => TQueryFnData | Promise<TQueryFnData>,
    options?: UseQueryOptions<TQueryFnData | null, TError, TData>
): UseQueryResult<TData, TError> {
    const { session } = useSession();

    const userId = session?.identity?.id;

    return useQuery(
        [queryKey, { userId }],
        context => {
            if (!userId) {
                return null;
            }

            const api = new apiType();
            return handler(api!, context);
        },
        Object.assign(
            {},
            {
                enabled: userId !== null,
                useErrorBoundary: true,
                retry: false,
                refetchOnWindowFocus: false,
            },
            options
        )
    );
}

export function useOrganizationApi<TApi extends ApiBase>(
    apiType: new (orgId: string) => TApi
) {
    const { organizationId } = useOrganization();
    if (organizationId) {
        return new apiType(organizationId);
    }

    throw new Error('Use organization api without organization id');
}

export function useOrganizationApiQuery<
    TApi extends ApiBase,
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData
>(
    queryKey: QueryKey,
    apiType: new (orgId: string) => TApi,
    handler: (
        api: TApi,
        context: QueryFunctionContext<any>
    ) => TQueryFnData | Promise<TQueryFnData>,
    options?: UseQueryOptions<TQueryFnData | null, TError, TData>
): UseQueryResult<TData, TError> {
    const { session } = useSession();
    const { organizationId } = useOrganization();

    const userId = session?.identity?.id;

    return useQuery(
        [queryKey, { userId, organizationId }],
        context => {
            if (!userId || !organizationId) {
                return null;
            }

            const api = new apiType(organizationId);
            return handler(api!, context);
        },
        Object.assign(
            {},
            {
                enabled: userId !== null,
                useErrorBoundary: true,
                retry: false,
                refetchOnWindowFocus: false,
            },
            options
        )
    );
}
